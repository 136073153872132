<template>
  <div class="home stage-wrap">
    <div class="admin-page">
      <h3 class="admin-page-title">
        Stage (테스트 서버)
        <p>개발 진행중인 신규/추가 서비스를 테스트 하실 수 있습니다.</p>
      </h3>
      <div class="stage-page-wrap itm">
        <h4>ITM</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stagehq.carrotenglish.net/auth/common/directLink?uid=${stage.email}`
              )
            "
          >
            HQ 매니저단
          </li>
          <li
            @click="
              stage.goStageServer('http://stagest.carrotenglish.net/login')
            "
          >
            학습자단
          </li>
          <li
            @click="
              stage.goStageServer(
                'https://stageteacher.carrotenglish.net/login'
              )
            "
          >
            강사단
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stageitm.carrotenglish.net/rest/mr8/admin_ssologin?email=${stage.email}`
              )
            "
          >
            팔선생 관리자단
          </li>
          <li
            @click="stage.goStageServer('https://stagejob.carrotenglish.net/')"
          >
            잡포스팅
          </li>
          <li
            @click="
              stage.goStageServer('https://stage-myclass.carrotenglish.net/')
            "
          >
            My Class<br />(테스트전용)
          </li>
          <li
            @click="
              stage.goStageServer(
                'https://kr.object.ncloudstorage.com/apks/myclass/myclass_230601.apk'
              )
            "
          >
            My Class(안드로이드앱)
          </li>
          <!-- <li
            @click="
              stage.goStageServer(
                'itms-services://?action=download-manifest&url=https://kr.object.ncloudstorage.com/apks/myclass/ios/manifest.plist'
              )
            "
          >
            My Class(아이폰앱)
          </li> -->
          <li
            @click="
              stage.goStageServer(
                'https://kr.object.ncloudstorage.com/apks/myclassnfc/MyClassNFC.apk'
              )
            "
          >
            My Class NFC(안드로이드앱)
          </li>
          <li
            @click="
              stage.goStageServer(
                'itms-services://?action=download-manifest&url=https://kr.object.ncloudstorage.com/apks/myclassnfc/ios/manifest.plist'
              )
            "
          >
            My Class NFC(아이폰앱)
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap ctm">
        <h4>CTM</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stagectm.carrotenglish.kr/act/outlogin?email=${stage.email}`
              )
            "
          >
            HQ 매니저단
          </li>
          <li
            @click="
              stage.goStageServer(
                'https://stagetctm.carrotenglish.kr/act/login'
              )
            "
          >
            강사단
          </li>
          <li @click="stage.goStageServer('https://stage.carrotenglish.kr')">
            학습자단(성인)
          </li>
          <li @click="stage.goStageServer('https://stage.carrotjr.com')">
            학습자단(주니어)
          </li>
          <li
            @click="
              stage.goStageServer('https://stage.carrotenglishtutor.com/')
            "
          >
            강사채용사이트
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap imooc">
        <h4>i:MOOC</h4>
        <ul>
          <li @click="stage.goStageServer('https://www.liveesl.co.kr/')">
            홈페이지
          </li>
          <li
            @click="
              stage.goStageServer(
                `http://admin.liveesl.co.kr/rest/auth/login?email=${stage.email}&kname=${stage.kname}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>

      <div class="stage-page-wrap carrot-app">
        <h4>통합플랫폼</h4>
        <ul>
          <li
            @click="
              stage.goStageServer('https://platform.carrotenglish.net/beta')
            "
          >
            앱 다운로드
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stage-platform.carrotenglish.net/login?uid=${stage.email}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>수강신청</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `http://stage-itmsugang-admin.carrotenglish.net/users/carrot-login?email=${stage.email}&idx=${stage.idx}`
              )
            "
          >
            ITM 수강신청
          </li>
          <li
            @click="
              stage.goStageServer(
                `http://applystage.carrotenglish.net/rest/auth/login?kname=${stage.kname}&email=${stage.email}`
              )
            "
          >
            CTM 수강신청
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap olivepop">
        <h4>올리브팝</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `http://stagehq.olivepop.net/login?id=${stage.email}&idx=${stage.idx}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>

      <div class="stage-page-wrap spac">
        <h4>SPAC - WRITING</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `http://stagespac-hq.carrotglobal.com/rest/common/autoLogin?uid=${stage.email}&type=hq`
              )
            "
          >
            HQ 관리자단
          </li>
          <li
            @click="
              stage.goStageServer(
                `http://stagespac-rater.carrotglobal.com/rest/common/autoLogin?uid=${stage.email}&type=rater`
              )
            "
          >
            평가자단
          </li>
          <li
            @click="
              stage.goStageServer(`https://stagespac-student.carrotglobal.com/`)
            "
          >
            학습자단
          </li>
          <li
            @click="
              stage.goStageServer(
                `http://stage-admin.ktngleader.com/Assignment?uid=${stage.email}`
              )
            "
          >
            KT&G 관리자
          </li>
          <li @click="stage.goStageServer(`http://stage.ktngleader.com/`)">
            KT&G 사용자
          </li>
          <li
            @click="
              stage.goStageServer(`https://stage.samsungbpo.com/samsung/login`)
            "
          >
            삼성전자DS BPO 관리자(삼성)
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stage.samsungbpo.com/carrot/login?email=${stage.email}`
              )
            "
          >
            삼성전자DS BPO 관리자(캐럿)
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stagestaff.samsungbpo.com/staff/login`
              )
            "
          >
            삼성전자DS BPO 관리자(Staff)
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap speaking">
        <h4>스피킹랩</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stagespeaking2.carrotenglish.net/auth/3oe8rh?kname=${stage.kname}&uidx=${stage.idx}`
              )
            "
          >
            관리자단
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://kr.object.ncloudstorage.com/speakinglab2/android_apk/speaking_lab_20220310.apk`
              )
            "
          >
            Android 앱 다운받기
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://apps.apple.com/us/app/speakinglab/id6463774714`
              )
            "
          >
            IOS 앱 다운로드
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap behappy">
        <h4>밝음 CRM(상담사,관리자,센터장)</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(`http://stagecrm.carrotenglish.net/login`)
            "
          >
            관리자단
          </li>
        </ul>
      </div>

      <div class="stage-page-wrap behappy">
        <h4>밝음 EAP</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `http://adminstage.bhappy.co.kr/act/outlogin?email=${stage.email}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap imooc">
        <h4>LMS</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stage-reuters.carrotenglish.net/login?uid=${stage.email}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>Pronunciation</h4>
        <ul>
          <li>
            <a
              target="_blank"
              @click="
                stage.goStageServer(
                  `http://prontest.carrotenglish.net/outlogin?idx=${stage.idx}&uid=${stage.email}&ename=${stage.ename}`
                )
              "
              >Pronunciation Evaluate Test</a
            >
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>랭기지랩</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stage-langlab-admin.carrotglobal.com/login?uid=${stage.email}`
              )
            "
          >
            랭기지랩 Admin
          </li>
          <li
            @click="
              stage.goStageServer(`https://stage-langlab.carrotglobal.com/`)
            "
          >
            랭기지랩 User
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stage-langlab-tutor.carrotglobal.com/`
              )
            "
          >
            랭기지랩 Tutor
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>코칭플랫폼 (BnB)</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://bnb-stage-admin.carrotglobal.com/?userId=${stage.email}`
              )
            "
          >
            Admin
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://bnb-stage-admin.carrotglobal.com/manager/login`
              )
            "
          >
            담당자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>고양시청</h4>
        <ul>
          <li>
            <a
              target="_blank"
              @click="
                stage.goStageServer(`https://stage-goyang.carrotglobal.com`)
              "
              >고양시청</a
            >
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap ctm">
        <h4>Survey</h4>
        <ul>
          <li>
            <a
              target="_blank"
              @click="
                stage.goStageServer(
                  `http://stage.carrotsurvey.com/rest/common/loginHQ?uid=${stage.email}&returnUrl=/admin/index/`
                )
              "
              >CARROT Survey</a
            >
          </li>
        </ul>
      </div>

      <div class="stage-page-wrap imooc">
        <h4>biz.carrotenglish</h4>
        <ul>
          <li @click="stage.goStageServer(`https://biz.carrotenglish.com`)">
            biz.carrotenglish
          </li>
        </ul>
      </div>

      <div class="stage-page-wrap itm">
        <h4>통합수강신청</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stage-integrated-sugang-admin.carrotapply.com/login?email=${stage.email}&idx=${stage.idx}`
              )
            "
          >
            통합수강신청
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap carrot-app">
        <h4>맞춤 표현집</h4>
        <ul>
          <li
            @click="
              stage.goStageServer('https://carrotglobal.com/biz_english/down')
            "
          >
            맞춤표현집 앱 다운로드
          </li>

          <!-- <li
            @click="
              stage.goStageServer(
                'itms-services://?action=download-manifest&url=https://kr.object.ncloudstorage.com/apks/customexpbook/ios/manifest.plist'
              )
            "
          >
            맞춤 표현집(IOS)
          </li> -->
          <li
            @click="
              stage.goStageServer('https://stage-expadmin.carrotenglish.net/')
            "
          >
            관리자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap behappy">
        <h4>AI튜터 관리자단</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stage-edutech.carrotenglish.net/connectionHQ?email=${stage.email}&idx=${stage.idx}`
              )
            "
          >
            관리자단
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap carrot-app">
        <h4>MangoTalk</h4>
        <ul>
          <li
            @click="stage.goStageServer(`https://stage-tutor.mangotalk.co.kr`)"
          >
            Tutor
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stage-hq.mangotalk.co.kr/login?email=${stage.email}&idx=${stage.idx}`
              )
            "
          >
            HQ
          </li>
          <li
            @click="
              stage.goStageServer(
                `https://stage-topic-admin.mangotalk.co.kr/act/outlogin?email=${stage.email}`
              )
            "
          >
            교재관리
          </li>
          <li
            @click="stage.goStageServer(`http://stage-recruit.mangotalk.co.kr`)"
          >
            강사채용
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap behappy">
        <h4>평생교육 글로벌역량 플랫폼</h4>
        <ul>
          <!-- admin -->
          <!-- stage -->
          <li
            @click="
              stage.goStageServer(
                `https://stage-life-admin.carrotapply.com/connectionHQ?email=${stage.email}&${stage.idx}`
              )
            "
          >
            관리자단
          </li>

          <!-- master -->
          <!-- <li @click="stage.goStageServer(`https://life-admin.carrotapply.com/connectionHQ?email=${stage.email}&${stage.idx}`)">관리자단</li> -->

          <!-- local -->
          <!-- <li @click="stage.goStageServer(`http://localhost:8080/connectionHQ?email=${stage.email}&${stage.idx}`)">관리자단</li> -->

          <!-- user -->
          <!-- stage -->
          <!-- <li @click="stage.goStageServer(`https://stage-lifeedu.carrotenglish.net/home`)">사용자단</li> -->

          <!-- local -->
          <!-- <li @click="stage.goStageServer(`http://localhost:8080/connectionHQ?email=${stage.email}&${stage.idx}`)">관리자단</li> -->
        </ul>
      </div>
      <div class="stage-page-wrap behappy">
        <h4>2024 컨퍼런스</h4>
        <ul>
          <li
            @click="
              stage.goStageServer('https://stage-conf2024.carrotglobal.com/')
            "
          >
            2024 컨퍼런스
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap imooc">
        <h4>SK 바이오 사이언스</h4>
        <ul>
          <li @click="stage.goStageServer(`https://carrotskbiosc.co.kr`)">
            SK 바이오 사이언스
          </li>
        </ul>
      </div>
      <div class="stage-page-wrap itm">
        <h4>AI Tutor Plus</h4>
        <ul>
          <li
            @click="
              stage.goStageServer(
                `https://stage-admin.aitutoring.co.kr/connectionHQ?email=${stage.email}`
              )
            "
          >
            관리자 페이지
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    const stage = reactive({
      idx: store.state.idx_staff,
      email: store.state.email,
      kname: store.state.kname,
      ename: store.state.ename,

      /**
       *  @description : 스테이지 서버
       */
      goStageServer(url) {
        window.open(url);
      },
    });

    onMounted(() => {
      console.log("Stage onMounted");
      console.log("###", stage.email);
    });

    onUnmounted(() => {
      console.log("Stage onUnmounted");
    });

    return { stage };
  },
};
</script>
